import React from "react"
import { Logo } from "./logo"
import { MobileLogo } from "./logoMobile"
import { Link } from "gatsby"
import NavDrawer from "./navdrawer"

// Styles
import styles from "../styles/components/header.module.scss"

class BlogHeader extends React.Component {
  state = {
    fixed: false,
    offset: 0,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleResize)
  }

  handleResize = e => {
    if (e.target.innerWidth > 1040) {
      this.setState({
        fixed: false,
      })
    }
  }

  handleScroll = e => {
    if (window.innerWidth > 1040) {
      return
    }
    const offset = window.pageYOffset
    if (this.state.offset > offset) {
      this.setState({
        fixed: true,
      })
    } else {
      this.setState({
        fixed: false,
      })
    }
    this.setState({
      offset,
    })
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
    window.addEventListener("resize", this.handleResize)
  }

  render() {
    return (
      <header>
        <div className={styles.blogBar}>
          <div className={styles.logo}>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <Link to="/">
            <div className={styles.mobileLogo}>
              <MobileLogo />
            </div>
          </Link>
          <div className={styles.navdrawer}>
            <NavDrawer blog={true} />
          </div>
          <div className={styles.navigation}>
            <div className={styles.links}>
              <li>
                <Link to="/">Go Back</Link>
              </li>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default BlogHeader
