import React from "react"
import SEO from "../components/seo"
import Header from "../components/blogHeader"
import Footer from "../components/footer"
import AboutUs from "../components/chief"
import Separator from "../components/separator"
import BlogGrid from "../components/blogGrid"

const AboutPage = () => {
  return (
    <>
      <Header />

      <SEO
        title="Lifetime Fitness Blog"
        description="Lifetime Fitness is more than just a gym - it's a caring family that's there to help you achieve your goals. Join our gym family today!"
      />
      <AboutUs />
      <Separator />
      <BlogGrid />

      <Footer />
    </>
  )
}

export default AboutPage
