import React from "react"
import Title from "./title"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// Styles
import styles from "../styles/components/chief.module.scss"
import { AboutCarousel } from "./carousel"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      maduranga: file(relativePath: { eq: "about2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      signature: file(relativePath: { eq: "signature-w.png" }) {
        childImageSharp {
          fixed(width: 200, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div className={styles.main}>
      <div className={styles.title}>
        <Title text="More Than A Gym" />
      </div>
      <div className={styles.grid}>
        <div className={styles.content}>
          <p>
            We focus on providing a premium and intimate service to everyone.
            All Lifetime Fitness members receive a personal fitness
            consultation, tailored diet plan and training regimen, as well as
            one to one training by professional, trained instructors.
            <br />
            <br />
            We like to think that we are like a family here; we give special
            attention to providing a home-like atmosphere so that members can
            stay consistent and truly enjoy their workouts!
          </p>
          <div className={styles.signature}>
            <Img fixed={data.signature.childImageSharp.fixed} alt="" />

            <h4>Maduranga Perera</h4>
            <p>- Founder, Lifetime Fitness</p>
          </div>
        </div>
        <div className={styles.image}>
          <AboutCarousel />
        </div>
      </div>
    </div>
  )
}

export default AboutUs
